
import { Component, Vue } from "vue-property-decorator";
import OrderApi from "@/services/api/order";
import SettingsApi from "@/services/api/settings";
import { AxiosError, AxiosResponse } from "axios";
import Order from "@/models/order";
import { addAnalyticsEvent } from "@/plugins/firebase";
import BackButton from "@/components/BackButton.vue";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import AlertBox from "@/components/AlertBox.vue";
import CompositeAlert from "@/components/CompositeAlert.vue";
import Settings from "@/models/settings";
import { Getter } from "vuex-class";
import { User } from "@/models/user";
import { ApiResponse } from "@/services/api/axios";

@Component({
    components: {
        CompositeAlert,
        AlertBox,
        LoadingSpinner,
        BackButton,
    },
})
export default class CoinPaymentsCheckout extends Vue {
    order: Order | null = null;
    settings: Settings | null = null;

    @Getter("user") user!: User | null;

    get cancelURL(): string {
        return (
            window.location.origin +
            "/orders/" +
            this.order?.orderId +
            "?status=cancelled"
        );
    }
    get successURL(): string {
        return (
            window.location.origin +
            "/orders/" +
            this.order?.orderId +
            "?status=success"
        );
    }
    mounted() {
        addAnalyticsEvent("coinpayments_redirect");
        Promise.all([this.loadOrder(), this.loadSettings()]).then(
            this.submitForm
        );
    }

    submitForm() {
        setTimeout(() => {
            (this.$refs["checkoutForm"] as HTMLFormElement).submit();
        }, 1000);
        return;
    }

    loadSettings(): Promise<void> {
        return SettingsApi.get()
            .then((response: AxiosResponse) => {
                this.settings = new Settings(response.data.data);
            })
            .catch(this.handleAxiosError);
    }

    loadOrder(): Promise<void> {
        return OrderApi.get(this.$route.params.orderId)
            .then((response: AxiosResponse) => {
                this.order = new Order(response.data.data);
            })
            .catch(this.handleAxiosError);
    }

    handleAxiosError(error: AxiosError<ApiResponse>) {
        this.$router.push({ name: this.$constants.ROUTE_NAMES.HOME });
        this.$root.$emit(
            this.$constants.NOTIFICATION_EVENTS.ERROR,
            error.response?.data?.message ?? error.message
        );
    }
}
